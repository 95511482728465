import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/global-functions/checkRequestErrors";
export const applayCouponFunc = createAsyncThunk(
    "coupon/applay",
    async ({data , api}) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.post(api , data , config);
            return response.data.data.coupon
        }
        catch(error) {
            checkErrors(error.response , error.message);
        }
    }
)
const applayCouponSlicer = createSlice({
    name : "coupon/applay",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {
        clearCoupon : (state) => {
            state.data = null;
        }
    },
    extraReducers : builder => {
        builder.addCase(applayCouponFunc.pending , (state) => {
            state.loading = true
        })
        builder.addCase(applayCouponFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(applayCouponFunc.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        })
    }
})
export const {clearCoupon} = applayCouponSlicer.actions
export default applayCouponSlicer.reducer