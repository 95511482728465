import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { checkErrors } from "../../../../global/global-functions/checkRequestErrors";
export const loginFunction = createAsyncThunk(
    "login func",
    async ({data , api}) => {
        try {
            const response = await axios.post(api , data);
            if(response.data.success === true){
                const token = response.data.data.authorisation.token;
                const decodeToken = jwtDecode(response.data.data.authorisation.token);
                localStorage.setItem("expire_date", decodeToken.exp);
                localStorage.setItem("token", token);
                const user = {
                    id : response.data.data.user.id,
                    name : `${response.data.data.user.first_name} ${response.data.data.user.last_name}`,
                    email : response.data.data.user.email,
                    image : response.data.data.user.image_url,
                    verifiedStatus : response.data.data.user.is_email_verified,
                }
                localStorage.setItem("user", JSON.stringify(user));
                return response.data
            }
        }
        catch(error) {
            checkErrors(error.response , error.message);
        }
    }
)
const loginSlicer = createSlice({
    name : "login slicer",
    initialState : {
        loading : null,
        data : {},
        error : null
    },
    reducers : {},
    extraReducers : builder => {
        builder.addCase(loginFunction.pending , (state) => {
            state.loading = true
        })
        builder.addCase(loginFunction.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(loginFunction.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        })
    }
})

export default loginSlicer.reducer



// <head>
	
// 	<!-- Meta -->
// 	<meta charset="utf-8">
// 	<meta http-equiv="X-UA-Compatible" content="IE=edge">
// 	<meta name="keywords" content="" />
// 	<meta name="author" content="DexignZone" />
// 	<meta name="robots" content="" />
// 	<meta name="description" content="Bookland-Book Store Ecommerce Website"/>
// 	<meta property="og:title" content="Bookland-Book Store Ecommerce Website"/>
// 	<meta property="og:description" content="Bookland-Book Store Ecommerce Website"/>
// 	<meta property="og:image" content="https://makaanlelo.com/tf_products_007/bookland/xhtml/social-image.png"/>
// 	<meta name="format-detection" content="telephone=no">
	
// 	<!-- FAVICONS ICON -->
// 	<link rel="icon" type="image/x-icon" href="images/favicon.png"/>
	
// 	<!-- PAGE TITLE HERE -->
// 	<title>Bookland Book Store Ecommerce Website</title>
	
// 	<!-- MOBILE SPECIFIC -->
// 	<meta name="viewport" content="width=device-width, initial-scale=1">
	
// 	<!-- STYLESHEETS -->
// 	<link rel="stylesheet" type="text/css" href="vendor/bootstrap-select/dist/css/bootstrap-select.min.css">
// 	<link rel="stylesheet" type="text/css" href="icons/fontawesome/css/all.min.css">
// 	<link rel="stylesheet" type="text/css" href="vendor/swiper/swiper-bundle.min.css">
// 	<link rel="stylesheet" type="text/css" href="vendor/animate/animate.css">
// 	<link rel="stylesheet" type="text/css" href="css/style.css">
	
// 	<!-- GOOGLE FONTS-->
// 	<link rel="preconnect" href="https://fonts.googleapis.com">
// 	<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
// 	<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">

// </head>	