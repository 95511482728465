// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// const recourses = {
//     en : "Welcome"
// }

// i18n
//     .use(LanguageDetector)
//     .use(initReactI18next)
//     .init({
//         recourses,
//         lng : "end",
//         interpolation : {
//             escapeValue : false
//         },
//         react : {
//             useSuspense : false
//         }
//     })

// export default i18n;





import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // Connects with React
  .init({
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;