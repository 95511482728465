import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../../global/global-functions/checkRequestErrors";

export const changeUserImageFunc = createAsyncThunk(
    "profile/updateImage",
    async ({api , data}) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            const response = await axios.post(api , data , config);
            return response.data
        }
        catch (error) {
            checkErrors(error.response , error.message)
            return error.response
        }
    }
);

const changeUserImageSlicer = createSlice({
    name : "profile/updateImage",
    initialState : {
        loading : false,
        data : null,
        error : false
    },
    extraReducers : (builder) => {
        builder.addCase(changeUserImageFunc.pending , (state) =>{
            state.loading = true
            state.data = null
            state.error = false
        })
        builder.addCase(changeUserImageFunc.rejected , (state) =>{
            state.loading = false
            state.data = null
            state.error = true
        })
        builder.addCase(changeUserImageFunc.fulfilled , (state , action) =>{
            state.loading = false
            state.data = action.payload
            state.error = false
        })
    }
})
export default changeUserImageSlicer.reducer;