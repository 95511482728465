import { Link } from "react-router-dom";
import "./toggeler-lang.css";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ContextApi } from "../../store/context/ContextApi";
import { getLanguageSettingsFunc } from "../../store/slicers/settings/language/GetLanguageSettingsSlicer";
export default function ToggelerLang({languages}) {
  const contextApi = useContext(ContextApi);
	const {loading:getLanguageLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  const languageAPi = `${contextApi.userpanelApi}/api/language-settings`;
  const dispatch = useDispatch();
  const [langSlug , setlangSlug] = useState("")
  useEffect(() => {
    if(languageData && languageData.defaultLang) {
      const dfLangSlug = languageData.defaultLang.slug
      setlangSlug(dfLangSlug)
    }
  },[languageData])
  const handleGetLang = (lang) => {
    if(lang.slug) {
      if(lang.slug !== langSlug) {
        // setlangSlug(lang.slug)
        dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${lang.slug}`));
      }
    }
  }
  return (
    <div className="dropdown">
      <button className="btn btn-secondary dropdown-toggle toggeler-lang" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{backgroundColor: "transparent" , color : "#0f0f0f" , borderColor : "transparent" , fontSize : "15px"}}>
        <i className="fa-solid fa-globe"></i>
      </button>
      <ul className="dropdown-menu flex-column">
        {
          languages && languages.length >= 1
          ?
          languages.map((lang , index) => (
            <li key={index} className="d-block m-0" style={{padding : 0}}
            onClick={() => handleGetLang(lang)}
            > <Link className="dropdown-item" to="#">{lang.name}</Link></li>
            ))
            :
            <li><Link className="dropdown-item" to="#" style={{pointerEvents : "none"}}>No Languages</Link></li>  
        }
      </ul>
    </div>
  );
}