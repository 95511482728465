import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../../global/global-functions/checkRequestErrors";

export const getProfileFunc = createAsyncThunk(
    "profile/get",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Accept"] = "application/json";
        try {
            const response = await axios.get(api , config);
            console.log(response.data)
            return response.data.data.user
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
    }
);

const getProfileSlicer = createSlice({
    name : "profile/get",
    initialState : {
        loading : false,
        data : null,
        error : false
    },
    extraReducers : (builder) => {
        builder.addCase(getProfileFunc.pending , (state) =>{
            state.loading = true
            state.data = null
            state.error = false
        })
        builder.addCase(getProfileFunc.rejected , (state) =>{
            state.loading = false
            state.data = null
            state.error = true
        })
        builder.addCase(getProfileFunc.fulfilled , (state , action) =>{
            state.loading = false
            state.data = action.payload
            state.error = false
        })
    }
})
export default getProfileSlicer.reducer;