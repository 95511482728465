import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../../global/global-functions/checkRequestErrors";

export const resendVerifEmailFunc = createAsyncThunk(
    "login",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Accept"] = "application/json";
        try {
            const response = await axios.post(api , {} , config);
            return response.data
        }
        catch (error) {
            checkErrors(error.response , error.message)
        }
    }
);
// verifyUserFunc
const resendVerifEmailSlicer = createSlice({
    name : "user logout",
    initialState : {
        loading : false,
        status : null,
        error : false
    },
    extraReducers : (builder) => {
        builder.addCase(resendVerifEmailFunc.pending , (state) =>{
            state.loading = true
            state.status = null
            state.error = false
        })
        builder.addCase(resendVerifEmailFunc.rejected , (state) =>{
            state.loading = false
            state.status = null
            state.error = true
        })
        builder.addCase(resendVerifEmailFunc.fulfilled , (state , action) =>{
            state.loading = false
            state.status = action.payload
            state.error = false
        })
    }
})
export default resendVerifEmailSlicer.reducer;