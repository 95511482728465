import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/global-functions/checkRequestErrors";
export const addOrderFunc = createAsyncThunk(
    "orders/add",
    async ({data , api}) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` ,// Include the token in the Authorization header
                'Content-Type': 'multipart/form-data',
            }
        }
        try {
            const response = await axios.post(api , data , config);
            return response.data
        }
        catch(error) {
            checkErrors(error.response , error.message);
        }
    }
)
const addOrderSlicer = createSlice({
    name : "orders/add",
    initialState : {
        loading : null,
        data : {},
        error : null
    },
    reducers : {},
    extraReducers : builder => {
        builder.addCase(addOrderFunc.pending , (state) => {
            state.loading = true
        })
        builder.addCase(addOrderFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(addOrderFunc.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        })
    }
})

export default addOrderSlicer.reducer