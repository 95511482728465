import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/global-functions/checkRequestErrors";
export const getSubCategoriesFuc = createAsyncThunk(
    "subCategories/get",
    async (api) => {
        try {
            const response = await axios.get(api);
            return response.data.data.subCategories
        }
        catch(error) {
            checkErrors(error.response , error.message);
            return error
        }
    }
)
const getSubCategoriesSlicer = createSlice({
    name : "subCategories/get",
    initialState : {
        loading : null,
        data : {},
        error : null
    },
    reducers : {},
    extraReducers : builder => {
        builder.addCase(getSubCategoriesFuc.pending , (state) => {
            state.loading = true
        })
        builder.addCase(getSubCategoriesFuc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(getSubCategoriesFuc.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        })
    }
})

export default getSubCategoriesSlicer.reducer