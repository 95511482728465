import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import { checkErrors } from "../../../global/global-functions/checkRequestErrors";

export const updateCartFunc = createAsyncThunk(
    "cart/update",
    async ({api , data}) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.put(api , data , config);
            return response.data
        }
        catch(error) {
            // checkErrors(error.response , error.message);
            return error
        }
    }
)
const updateCartSlicer = createSlice({
    name : "cart/update",
    initialState : {
        loading : false,
        data : null,
        error : false
    },
    extraReducers : builder => {
        builder.addCase(updateCartFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = false
        })
        builder.addCase(updateCartFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(updateCartFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        })
    }
})
export default updateCartSlicer.reducer