import { Link } from "react-router-dom"
import SubscribeNewsLetters from "../forms/news-letters/NewsLetterForm"
import { useContext, useEffect, useRef, useState } from "react";
import { ContextApi } from "../../store/context/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import CategoryItem from "./CategoryItem";
import { getSubCategoriesFuc } from "../../store/slicers/sub-categories/GetSubCategoriesSlicer";
import { useTranslation } from "react-i18next";
import useFetchOnVisible from "../../pages/home/fetchwhenvisible";
// import SubscribeNewsLetters from "../forms/news-letters/NewsLetterForm"
export default function Footer() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const copyrightContent = contextApi.siteInfo.site_footer_copyright;
    const facebook_link = contextApi.siteInfo.facebook_link;
    const youtube_link = contextApi.siteInfo.youtube_link;
    const twitter_link = contextApi.siteInfo.twitter_link;
    const instagram_link = contextApi.siteInfo.instagram_link;
    const linkedin_link = contextApi.siteInfo.linkedin_link;
    const contact_phone = contextApi.siteInfo.contact_phone;
    const contact_email = contextApi.siteInfo.contact_email;
    const contact_address = contextApi.siteInfo.contact_address;
    const subCatApi = `${contextApi.userpanelApi}/api/sub-categories`;
    const dispatch = useDispatch()
    const {loading:getCategoriesLoading , data:categoriesData} = useSelector(
        state => state.getCategoriesSlicer
    )
    // const {data:subCategoriesData} = useSelector(
    //     state => state.getSubCategoriesSlicer
    // )
    const [categoriesList , setCategoriesList] = useState([]);
    const {data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
    // useEffect(() => {
    //     dispatch(getSubCategoriesFuc(subCatApi))
    // },[]);
    // const footerRef = useRef(null);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             entries.forEach((entry) => {
    //                     if (entry.isIntersecting) {
    //                         console.log('Footer section is in view!');
    //                     dispatch(getSubCategoriesFuc(subCatApi))
    //                 }
    //             });
    //         },
    //         {
    //             root: null, // null means it observes the viewport
    //             rootMargin: '0px',
    //             threshold: 0.1 // Trigger when 10% of the footer is visible
    //         }
    //     );

    //     if (footerRef.current) {
    //         observer.observe(footerRef.current);
    //     }

    //     // Cleanup the observer when the component unmounts
    //     return () => {
    //         if (footerRef.current) {
    //             observer.unobserve(footerRef.current);
    //         }
    //     };
    // }, []);

    // const targetRef = useFetchOnVisible(() => {
    //     dispatch(getSubCategoriesFuc(subCatApi));
    // }, {
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 0.1,
    // });
    useEffect(() => {
        categoriesData && setCategoriesList(categoriesData);
    },[categoriesData]);
    
    return (
        <>
        <SubscribeNewsLetters/>
        <footer className="site-footer style-1">
            {/* <div className="footer-category" style={{transition: "all .5s ease"}}>
            {
                categoriesList.length>=1 && categoriesList.map((cat , index) => (
                    <CategoryItem key={index} category={cat} subCat={subCategoriesData && subCategoriesData}/>
                ))
            }
            </div> */}
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="widget widget_about">
                                <div className="footer-logo logo-white">
                                    <Link to="index.html"><img src="images/logo.png" alt=""/></Link> 
                                </div>
                                <p className="text">{t("Footer Content")}</p>
                                <div className="dz-social-icon style-1">
                                    <ul>
                                        <li><Link to={facebook_link} target="_blank" ><i className="fa-brands fa-facebook-f"></i></Link></li>
                                        <li><Link to={youtube_link} target="_blank"><i className="fa-brands fa-youtube"></i></Link></li>
                                        <li><Link to={linkedin_link} target="_blank"><i className="fa-brands fa-linkedin"></i></Link></li>
                                        <li><Link to={instagram_link} target="_blank"><i className="fa-brands fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="widget widget_services">
                                <h5 className="footer-title">{t("Categories")}</h5>
                                <ul>
                                    {
                                        categoriesList && categoriesList.length >= 1
                                        &&
                                        categoriesList.map((cat , index) => (
                                            <li key={index}><Link to="#">{cat.name}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="widget widget_services">
                                <h5 className="/">{t("Resources")}</h5>
                                <ul>
                                    <li><Link to="/">{t("Home")}</Link></li>
                                    <li><Link to="/products">{t("Shop")}</Link></li>
                                    <li><Link to="/contact">{t("Contact Us")}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="widget widget_getintuch">
                                <h5 className="footer-title">{t("Get in Touch With Us")}</h5>
                                <ul>
                                    <li>
                                        <i className="flaticon-placeholder"></i>
                                        <span>{contact_address}</span>
                                    </li>
                                    <li>
                                        <i className="flaticon-phone"></i>
                                        <span>{contact_phone}<br/></span>
                                    </li>
                                    <li>
                                        <i className="flaticon-email"></i> 
                                        <span>{contact_email}<br/></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row fb-inner">
                        <div className="text-center" dangerouslySetInnerHTML={{__html : copyrightContent}} />
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}