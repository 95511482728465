// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Define the initial state of the counter
const initialState = {
  coupon: null,
};

// Create a slice of the state with name, initial state, and reducers
const saveCouponSlicer = createSlice({
  name: 'coupon/save',
  initialState,
  reducers: {
    save: (state) => {
    //   state.coupon = 1;
    console.log(state)
    },
    clear: (state) => {
    //   state.value -= 1;
    console.log(state)
    },
  },
});

// Export the actions generated from the slice
export const { save, clear } = saveCouponSlicer.actions;
export default saveCouponSlicer.reducer;
