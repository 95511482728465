import { toast } from "react-toastify";

export function checkErrors (response , errorMessage) {
    if(response) {
        if(response.data && response.data.errors && (response.data.errors.length >= 1 || Object.keys(response.data.errors).length >=1)) {
            const errorsObject = response.data.errors;
            Object.keys(errorsObject).forEach((key) => {
                errorsObject[key].forEach((errorMessage) => {
                  toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
              });
        }else {
            if(response.data && response.data.message) {
                if(response.status === 401 && response.data.message === "Unauthenticated."){
                    // toast.error(response.data.message, {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // });
                    if (window.location.pathname !== "/login") {
                        window.localStorage.clear();
                        window.location.pathname = "/login";
                    }
                }else {
                    if(response.data.message === "Please activate the verification code") {
                        window.location.href = "/verify-register-email"
                    }else {
                        // Swal.fire({
                        //     title: "Error!",
                        //     text: response.data.message,
                        //     icon: 'error',
                        //     confirmButtonText: 'OK',
                        //     customClass : {
                        //             popup : "z-index-class"
                        //         }
                        // });
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            }
        }
    }else {
        if(errorMessage === "Unauthenticated.") {
            // toast.error(errorMessage, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            console.log(window.location.pathname)
            if (window.location.pathname !== "/login") {
                window.localStorage.clear();
                window.location.pathname = "/login";
            }
        }else {
            // Swal.fire({
            //     title: "Error!",
            //     text: errorMessage,
            //     icon: 'error',
            //     confirmButtonText: 'OK',
            //     customClass : {
            //         popup : "z-index-class"
            //     }
            // });
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }
}