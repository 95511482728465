import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/global-functions/checkRequestErrors";
export const sendSubscribeNewsSFunc = createAsyncThunk(
    "send subscribe news func",
    async ({data , api}) => {
        try {
            const response = await axios.post(api , data);
            return response.data
        }
        catch(error) {
            checkErrors(error.response , error.message);
        }
    }
)
const sendSubscribeNewsSlicer = createSlice({
    name : "send subscribe news slicer",
    initialState : {
        loading : null,
        data : {},
        error : null
    },
    reducers : {},
    extraReducers : builder => {
        builder.addCase(sendSubscribeNewsSFunc.pending , (state) => {
            state.loading = true
        })
        builder.addCase(sendSubscribeNewsSFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(sendSubscribeNewsSFunc.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        })
    }
})

export default sendSubscribeNewsSlicer.reducer