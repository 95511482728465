import { useEffect } from "react";
import { toast } from "react-toastify";
function LogOut() {
    const expire_date = window.localStorage.getItem("expire_date")
    
    useEffect(() => {
        const checkerInterval = setInterval(() => {
          const dateNow = Date.now();
          const expirationTime = new Date(expire_date).getTime();
          const timeDifference = expirationTime - dateNow;
        if(timeDifference > 0 && timeDifference <= 60 * 1000) {
            toast.warning("session will end after 1 munite!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
          if (dateNow > expirationTime) {
            window.localStorage.removeItem("expire_date");
            window.localStorage.removeItem("user");
            window.location.reload();
          }
        }, 60 * 1000); // Check every 1 minute
        return () => clearInterval(checkerInterval);
      }, [expire_date]);
    return (
        <>
        </>
    )
}
export default LogOut;