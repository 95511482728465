import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ContextApi } from "../../../store/context/ContextApi";
import { sendSubscribeNewsSFunc } from "../../../store/slicers/news-lestters/SendSubscribeNews";
import { useNavigate } from "react-router-dom";
import "./news-letters.css"
import { useTranslation } from "react-i18next";

export default function SubscribeNewsLetters() {
    const {t} = useTranslation();
    const {loading:sendSubscribeLoading} = useSelector(
        state => state.sendSubscribeNewsSlicer
    )
    const { register, handleSubmit, formState: { errors } , reset} = useForm();
	const contextApi = useContext(ContextApi);
	const contactApi = `${contextApi.userpanelApi}/api/newsletters/subscribe`;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSendSubscribe = (data) => {
        try {
            dispatch(sendSubscribeNewsSFunc({api : contactApi , data})).then(result => {
				console.log(result)
				if(result.payload && result.payload.success === true) {
                    Swal.fire({
                        title: t("Done!"),
                        text: result.payload.message,
                        icon: "success"
                    });
                    reset();
                    // navigate("/verify-newsletter-email");
				}
			})
        }catch(error) {
            Swal.fire({
                title: t("Error!"),
                text: error,
                icon: "error"
            });
        }
    }
    return (
        <>
        <section className="py-5 newsletter-wrapper" 
            // style="background-image: url('images/background/bg1.jpg'); background-size: cover;"
            >
				<div className="container">
					<div className="subscride-inner">
						<div className="row style-1 justify-content-xl-between justify-content-lg-center align-items-center text-xl-start text-center">
							<div className="col-xl-7 col-lg-12">
								<div className="section-head mb-0">
									<h2 className="title text-white my-lg-3 mt-0">{t("Subscribe our newsletter for newest books updates")}</h2>
								</div>
							</div>
							<div className="col-xl-5 col-lg-6">
								<form 
                                    className={`style-1`} 
                                    onSubmit={handleSubmit((data) => {
                                        handleSendSubscribe(data)
                                    })}
                                    >
									<div className="dzSubscribeMsg"></div>
                                        <div className="form-group position-relative">
                                            <div className="input-group mb-0 overflow-hidden">
                                                {errors.message && <span className="text-red-500">{t("Email is required")}</span>}
                                                <input 
                                                {...register("email" , {required : t("Email Is Required")})}
                                                name="email"  
                                                type="email" 
                                                className="form-control subscribe-news-input bg-transparent text-white" placeholder={t("Your Email Address")}/>
                                                <div className="input-group-addon">
                                                    <button
                                                    name="submit" 
                                                    value="Submit" 
                                                    type="submit" 
                                                    className={`btn subscribe-news-btn btn-primary btnhover ${sendSubscribeLoading ? "pointer-events-none opacity-50" : ""}`}>
                                                        {
                                                            sendSubscribeLoading
                                                            ?
                                                            <span>{t("Loading")}..</span>
                                                            :
                                                            <span>{t("Subscribe")}</span>
                                                        }
                                                        <i className="fa-solid fa-paper-plane"></i>
                                                    </button>
                                                </div>
                                                {
                                                    sendSubscribeLoading
                                                    ?
                                                    <span className="position-absolute top-0 left-0 w-100 h-100 text-white d-flex justify-content-center align-items-center" style={{zIndex: 9, transform: "translateX(1px)" , backgroundColor : "#000000c2" , fontStyle : "italic"}}>{t("Loading..")}</span>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
        </>
    )
}