import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/global-functions/checkRequestErrors";
export const getCategoriesFuc = createAsyncThunk(
    "categories/get",
    async (api) => {
        try {
            const response = await axios.get(api);
            return response.data.data.categories
        }
        catch(error) {
            checkErrors(error.response , error.message);
            return error
        }
    }
)
const getCategoriesSlicer = createSlice({
    name : "categories/get",
    initialState : {
        loading : null,
        data : {},
        error : null
    },
    reducers : {},
    extraReducers : builder => {
        builder.addCase(getCategoriesFuc.pending , (state) => {
            state.loading = true
        })
        builder.addCase(getCategoriesFuc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(getCategoriesFuc.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        })
    }
})

export default getCategoriesSlicer.reducer