import { configureStore } from "@reduxjs/toolkit";
// auth
import loginSlicer from "./auth/login-slicer/LoginSlicer";
import forgotPassSlicer from "./auth/forgot-pass/ForgotPassSlicer";
import logoutSlicer from "./auth/logout-slicer/LogoutSlicer";
import registerSlicer from "./auth/register-slicer/RegisterSlicer";
import verifyUserSlicer from "./auth/verify-user-email/VerifyUserEmail";
import resendVerifEmailSlicer from "./auth/verify-user-email/ResendVerifEmailSlicer";
import refreshSessionSlicer from "./auth/refresh-session/RefreshSessionSlicer";
import getProfileSlicer from "./auth/profile/GetProfileSlicer";
import updateProfileBasicSlicer from "./auth/profile/UpdateProfileBasicSlicer";
import changePassSlicer from "./auth/change-pass/ChangePassSlicer";
import changeUserImageSlicer from "./auth/profile/ChangeUserImageSlicer";
import resetPassSlicer from "./auth/forgot-pass/ResetPassSlicer";

// contact
import sendContactMessageSlicer from "./contact-us/SendContactMessageSlicer";

// news letters
import sendSubscribeNewsSlicer from "./news-lestters/SendSubscribeNews";
import verifyNewsLetterEmailSlicer from "./news-lestters/VerifyNewsLetterEmailSlicer";

//products
import getProductsSlicer from "./products/GetProductsSlicer";
import getSingleProductSlicer from "./products/GetSingleProductSlicer";
import getRecommendeProductsSlicer from "./products/GetRecommendeProductsSlicer";
import getFeaturedProductsSlicer from "./products/GetFeaturedProductsSlicer";
import getBestSallerProductsSlicer from "./products/GetBestSallerProductsSlicer";

//cart
import getCartSlicer from "./cart/GetCartSlicer";
import addToCartSlicer from "./cart/AddToCartSlicer";
import deleteFromCartSlicer from "./cart/DeleteFromCartSlicer";
import updateCartSlicer from "./cart/UpdateCartSlicer";

//wishlist
import getWishlistSlicer from "./wishlist/GetWishlistSlicer";
import addToWishlistSlicer from "./wishlist/AddToWishlistSlicer";
import deleteFromWishlistSlicer from "./wishlist/DeleteFromWishlistSlicer";

//adressess
import getAdressesSlicer from "./adressess/GetAdressesSlicer";
import getSingleAddressSlicer from "./adressess/GetSingleAddressSlicer";
import addNewAdressSlicer from "./adressess/AddNewAdressSlicer";
import updateAddressSlicer from "./adressess/UpdateAddressSlicer";
import deleteAddressSlicer from "./adressess/DeleteAddressSlicer";
import getAllCountriesSlicer from "./adressess/GetAllCountriesSlicer";
import getAllStatesSlicer from "./adressess/GetAllStatesSlicer";
import getAllCitiesSlicer from "./adressess/GetAllCitiesSlicer";


//categories
import getCategoriesSlicer from "./categories/GetCategoriesSlicer";
//sub categories
import getSubCategoriesSlicer from "./sub-categories/GetSubCategoriesSlicer";

//coupons
import applayCouponSlicer from "./coupons/ApplayCouponSlicer";
import saveCouponSlicer from "./coupons/SaveCouponSlicer";

//orders
import getOrdersSlicer from "./orders/GetOrdersSlicer";
import addOrderSlicer from "./orders/AddOrderSlicer";
import getSingleOrderSlicer from "./orders/GetSingleOrderSlicer";

//paymnets
import getPaymentsSlicer from "./payment/GetPaymentsSlicer";

//global settings
import getGlobalSettingsSlicer from "./settings/GetGlobalSettingsSlicer";
import getLanguageSettingsSlicer from "./settings/language/GetLanguageSettingsSlicer";

//sliders
import getSlidersSlicer from "./sliders/GetSlidersSlicer";


export const Store = configureStore({
    reducer : {
        getSlidersSlicer,
        //auth
        loginSlicer,
        forgotPassSlicer,
        logoutSlicer,
        registerSlicer,
        verifyUserSlicer,
        resendVerifEmailSlicer,
        refreshSessionSlicer,
        getProfileSlicer,
        updateProfileBasicSlicer,
        changePassSlicer,
        changeUserImageSlicer,
        resetPassSlicer,
        //contact
        sendContactMessageSlicer,

        //news letters
        sendSubscribeNewsSlicer,
        verifyNewsLetterEmailSlicer,

        //products
        getProductsSlicer,
        getSingleProductSlicer,
        getRecommendeProductsSlicer,
        getFeaturedProductsSlicer,
        getBestSallerProductsSlicer,

        //cart
        getCartSlicer,
        addToCartSlicer,
        deleteFromCartSlicer,
        updateCartSlicer,

        //wishlist
        addToWishlistSlicer,
        getWishlistSlicer,
        deleteFromWishlistSlicer,

        //adressess
        getAdressesSlicer,
        getSingleAddressSlicer,
        addNewAdressSlicer,
        updateAddressSlicer,
        deleteAddressSlicer,
        getAllCountriesSlicer,
        getAllStatesSlicer,
        getAllCitiesSlicer,

        //categories
        getCategoriesSlicer,
        //sub categories
        getSubCategoriesSlicer,
        
        //coupons
        applayCouponSlicer,
        saveCouponSlicer,

        //orders
        getOrdersSlicer,
        addOrderSlicer,
        getSingleOrderSlicer,

        //paymnets
        getPaymentsSlicer,

        //settings
        getGlobalSettingsSlicer,
        //language
        getLanguageSettingsSlicer,
    }
})