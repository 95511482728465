import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/global-functions/checkRequestErrors";
export const sendContactMessageFunc = createAsyncThunk(
    "send contact message func",
    async ({data , api}) => {
        try {
            const response = await axios.post(api , data);
            console.log(response)
            return response.data
        }
        catch(error) {
            checkErrors(error.response , error.message);
            console.log(error.response)
            console.log(error.message)
        }
    }
)
const sendContactMessageSlicer = createSlice({
    name : "send contact message slicer",
    initialState : {
        loading : null,
        data : {},
        error : null
    },
    reducers : {},
    extraReducers : builder => {
        builder.addCase(sendContactMessageFunc.pending , (state) => {
            state.loading = true
        })
        builder.addCase(sendContactMessageFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(sendContactMessageFunc.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        })
    }
})

export default sendContactMessageSlicer.reducer