import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/global-functions/checkRequestErrors";

export const getCartFunc = createAsyncThunk(
    "cart/get",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.get(api , config);
            return response.data.data.carts;
        }
        catch(error) {
            checkErrors(error.response , error.message);
        }
    }
)
const getCartSlicer = createSlice({
    name : "cart/get",
    initialState : {
        loading : false,
        data : null,
        error : false
    },
    extraReducers : builder => {
        builder.addCase(getCartFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = false
        })
        builder.addCase(getCartFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(getCartFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        })
    }
})
export default getCartSlicer.reducer