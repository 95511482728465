import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { logoutFunc } from "../../store/slicers/auth/logout-slicer/LogoutSlicer";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../store/context/ContextApi";
import { refreshSessionFunc } from "../../store/slicers/auth/refresh-session/RefreshSessionSlicer";
import { getCategoriesFuc } from "../../store/slicers/categories/GetCategoriesSlicer";
import "./nav-bar.css";
import { useTranslation } from "react-i18next";
import { getProductsFunc } from "../../store/slicers/products/GetProductsSlicer";
import ToggelerLang from "../toggeler-lang/ToggelerLang";
export default function Navbar() {
	const {t} = useTranslation();
	const contextApi = useContext(ContextApi);
    const siteLogo = contextApi.siteInfo.site_logo;
	const logo = contextApi.logo;
	const profileImage = contextApi.profileImage;
	const url = new URL(window.location.href);
    const query = new URLSearchParams(url.search);
    const urlParams = new URLSearchParams(window.location.search);
    const categorySearch = urlParams.get("caegory_id");
    const searchParamsValue = urlParams.get('search');
	const facebook_link = contextApi.siteInfo.facebook_link;
    const twitter_link = contextApi.siteInfo.twitter_link;
    const instagram_link = contextApi.siteInfo.instagram_link;
    const linkedin_link = contextApi.siteInfo.linkedin_link;
    const {loading:logoutLoading} = useSelector(
        state => state.logoutSlicer
    )
    const {loading:refreshSessionLoading} = useSelector(
        state => state.refreshSessionSlicer
    )
	const {data:wishlistData} = useSelector(
        state => state.getWishlistSlicer
    )
	const {data:cartData} = useSelector(
        state => state.getCartSlicer
    )
	const {loading:getCategoriesLoading , data:categoriesData} = useSelector(
        state => state.getCategoriesSlicer
    )
	const user = JSON.parse(window.localStorage.getItem("user"));
	const logoutApi = `${contextApi.userpanelApi}/api/logout`;
	const refresSessionApi = `${contextApi.userpanelApi}/api/refresh`;
    const {loading:getProfileLoaing , data:profileData} = useSelector(
        state => state.getProfileSlicer
    )
	const {data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
	const [languagesList , setLanguagesList] = useState([])
	const [showCategoriesInSmScreens , setShowCategoriesInSmScreens] = useState(false)
	const categoriesApi = `${contextApi.userpanelApi}/api/categories`;
	const productsApi = `${contextApi.userpanelApi}/api/products?page=1`;
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const handleLogout = async () => {
		dispatch(logoutFunc(logoutApi))
		navigate('/login')
		window.localStorage.clear();
	}
	useEffect(() => {
		languageData && languageData.languages && setLanguagesList(languageData.languages);
	},[languageData])
    const [wishlist , setWishlist] = useState([])
    const [cartList , setCartList] = useState([])
    const [showLoginBtn , setShowLoginBtn] = useState(false);
	useEffect(() => {
		if(document.querySelector(".navbar-toggler")) {
			if(document.querySelector(".navbar-toggler").classList.contains("open")) {
				setShowLoginBtn(true)
			}else {
				setShowLoginBtn(false)
			}
		}
	},[])

	useEffect(() => {
		wishlistData && setWishlist(wishlistData)
	},[wishlistData])

	useEffect(() => {
		cartData && setCartList(cartData)
	},[cartData])
	const [searchValue , setSearchValue] = useState(searchParamsValue ? searchParamsValue : "")
	useEffect(() => {
		searchParamsValue ? setSearchValue(searchParamsValue) : setSearchValue("")
	},[searchParamsValue])
	const searchFunc = () => {
		searchValue!=="" && navigate(`/products?search=${searchValue}`)
	}
    useEffect(() => {
        const getCategoris = async () => {
			if(languageData && languageData.defaultLang) {
				const langSlug = languageData.defaultLang.slug;
				dispatch(getCategoriesFuc(`${categoriesApi}?lang=${langSlug}`))
			}
        }
        getCategoris()
    },[languageData])
	const [selectedOption, setSelectedOption] = useState('Category');
	const [categoryOption , setCategoryOption] = useState("")
	useEffect(() => {
		!categorySearch && setSelectedOption("Category")
	},[categorySearch])
	const handleCloseNav = () => {
		document.querySelector(".navbar-toggler").click()
	}

	const handleSearch = () => {
		if(categoryOption !=="") {
			navigate(`/products?search=${searchValue}&${categoryOption !== "" ? `caegory_id=${categoryOption}` : ""}`)
		}
	}
	const handleSelect = (optionName , optionId) => {
		setSelectedOption(optionName);
		setCategoryOption(optionId)
	}
	const clearSearchQuery = () => {
		setSearchValue("");
		setCategoryOption("");
		setSelectedOption("Category");
		if(window.location.pathname === "/products") {
			dispatch(getProductsFunc(productsApi))
		}
	}
    return (
        <>
			<header className="site-header mo-left header style-1">
				<div className="header-info-bar " style={{zIndex : 9999}}>
					<div className="container clearfix">
						<div className="logo-header logo-dark">
							<Link to="/"><img src={siteLogo} alt="logo"/></Link>
						</div>
						
						<div className="extra-nav">
							<div className="extra-cell">
								<ul className="navbar-nav header-right">
									{
										user
										&&
										<>
											<li className="nav-item">
												<Link className="nav-link" to="/wishlist">
													<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"/></svg>
													{
														wishlist.length >= 1
														?
														<span className="badge">{wishlist.length}</span>
														:
														""
													}
												</Link>
											</li>
											<li className="nav-item">
												<Link to={"/cart"} type="button" className="nav-link box cart-btn">
													<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
													{
														cartList.length >=1
														?
														<span className="badge">{cartList.length}</span>
														:
														""
													}
												</Link>
												{/* <ul className="dropdown-menu cart-list">
													<li className="cart-item">
														<div className="media"> 
															<div className="media-left"> 
																<a href="books-detail.html"> 
																	<img alt="" className="media-object" src={user && user.image ? user.image : "user"}/> 
																</a> 
															</div> 
															<div className="media-body"> 
																<h6 className="dz-title"><a href="books-detail.html" className="media-heading">Real Life</a></h6>
																<span className="dz-price">$28.00</span>
																<span className="item-close">&times;</span>
															</div> 
														</div>
													</li>
													<li className="cart-item">
														<div className="media"> 
															<div className="media-left"> 
																<Link to="#"> 
																	<img alt="" className="media-object" src={user && user.image ? user.image : "user"}/> 
																</Link> 
															</div> 
															<div className="media-body"> 
																<h6 className="dz-title"><a href="books-detail.html" className="media-heading">Home</a></h6>
																<span className="dz-price">$28.00</span>
																<span className="item-close">&times;</span>
															</div> 
														</div>
													</li>
													<li className="cart-item">
														<div className="media"> 
															<div className="media-left"> 
																<a href="books-detail.html"> 
																	<img alt="" className="media-object" src="images/books/small/pic3.jpg"/> 
																</a> 
															</div> 
															<div className="media-body"> 
																<h6 className="dz-title"><a href="books-detail.html" className="media-heading">Such a fun age</a></h6>
																<span className="dz-price">$28.00</span>
																<span className="item-close">&times;</span>
															</div> 
														</div>
													</li>
													<li className="cart-item text-center">
														<h6 className="text-secondary">Totle = $500</h6>
													</li>
													<li className="text-center d-flex">
														<a href="shop-cart.html" className="btn btn-sm btn-primary me-2 btnhover w-100">View Cart</a>
														<a href="shop-checkout.html" className="btn btn-sm btn-outline-primary btnhover w-100">Checkout</a>
													</li>
												</ul> */}
											</li>
										</>
									}
									{
										user
										&&
										<li className="nav-item dropdown profile-dropdown  ms-4">
											<Link className="nav-link" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
												{
													getProfileLoaing
													?
													<i className="fa-solid fa-arrows-rotate loading-verify-email"></i>
													:
													<img src={profileData && profileData.image_url ? profileData.image_url : profileImage} alt="/"/>
												}
												{/* <img src={user && user.image ? user.image : profileImage} alt="profile img"/> */}
												<div className="profile-info">
													<h6 className="title">{user && user.name}</h6>
													<span>{user && user.email}</span>
												</div>
											</Link>
											{
												user
												&&
												<div className="dropdown-menu py-0 dropdown-menu-end">
													<div className="dropdown-header">
														<h6 className="m-0">{user && user.name}</h6>
														<span>{user && user.email}</span>
													</div>
													<div className="dropdown-body">
														{
															user
															&&
															<>
															<Link to="/profile" className="dropdown-item d-flex justify-content-between align-items-center ai-icon">
																<div>
																	<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
																	<span className="ms-2">{t("Profile")}</span>
																</div>
															</Link>
															{/* <Link 
															to="#" className="dropdown-item d-flex justify-content-between align-items-center ai-icon"
															onClick={handleRefreshSession}
															>
																<div>
																<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000">
																<path d="M0 0h24v24H0V0z" fill="none"/>
																<path d="M11 8V4a1 1 0 0 1 1.707-.707l6.243 6.243a1 1 0 0 1 0 1.414l-6.243 6.243A1 1 0 0 1 11 16v-4c-3.374 0-6.454 1.167-8.918 3.107a1 1 0 0 1-1.441-1.39C3.481 10.815 7.057 8 11 8z"/>
																</svg>
																	<span className="ms-2">
																		{
																			refreshSessionLoading
																			?
																			"loading.."
																			:
																			<>
																			Refresh Session
																			</>
																		}
																	</span>
																</div>
															</Link> */}
															{/* <Link to="/orders" className="dropdown-item d-flex justify-content-between align-items-center ai-icon">
																<div>
																	<i className="fa-solid fa-border-all"></i>
																	<span className="ms-2">{t("My Orders	")}</span>
																</div>
															</Link> */}
															<Link to="/wishlist" className="dropdown-item d-flex justify-content-between align-items-center ai-icon">
																<div>
																	<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"/></svg>
																	<span className="ms-2">{t("Wishlist")}</span>
																</div>
															</Link>

															</>
														}
													</div>
													{
														user
														&&
														<div className="dropdown-footer" onClick={handleLogout}>
															<Link 
																className={`btn btn-primary w-100 btnhover btn-sm`} 
																to="#"
																
															>
																{
																	logoutLoading
																	?
																	t("Loading")
																	:
																	t("Log Out")
																}
															</Link>
														</div>
													}
												</div>
											}
										</li>
									}
								</ul>
							</div>
						</div>
						
						<div className="header-search-nav">
							<div className="header-item-search">
								<div className="input-group search-input">
									<div className="dropdown bootstrap-select default-select">
										<select
											className="default-select"
											tabIndex="null"
											value={selectedOption}
											// onChange={(e) => handleSelect(e.target.value)}
											style={{ display: 'none' }}
										>
											{/* Define options here if needed */}
											
												{categoriesData
												?
												(
													categoriesData && categoriesData.length >=1
													?
													categoriesData.map((cat , index) => {
														return <option key={index} value={cat.id}>{cat.name}</option>
													})
													:
													<option disabled selected>{t("No Categories")}</option>
												)
												:
												<option disabled>{t("Faild Load Categories")}</option>}
																			
										</select>
										<button
											type="button"
											tabIndex="-1"
											className="btn dropdown-toggle btn-light"
											data-bs-toggle="dropdown"
											role="combobox"
											aria-owns="bs-select-1"
											aria-haspopup="listbox"
											aria-expanded="false"
											title={selectedOption  === "Category" ? t("Category") : selectedOption}
											>
											<div className="filter-option">
											<div className="filter-option-inner">
												<div className="filter-option-inner-inner">{selectedOption === "Category" ? t("Category") : selectedOption}</div>
											</div>
											</div>
										</button>
										<div
											className="dropdown-menu"
											style={{ maxHeight: '493px', overflow: 'hidden', minHeight: '112px' }}
										>
											<div
											className="inner show"
											role="listbox"
											id="bs-select-1"
											tabIndex="-1"
											aria-activedescendant="bs-select-1-7"
											style={{ maxHeight: '477px', overflowY: 'auto', minHeight: '96px' }}
											>
											<ul
												className="dropdown-menu inner show"
												role="presentation"
												style={{ marginTop: '0px', marginBottom: '0px' }}
											>
												{/* <li className="dropdown-item" onClick={() => handleSelect('Comics')}>Comics</li>
												<li className="dropdown-item" onClick={() => handleSelect('Movies')}>Movies</li>
												<li className="dropdown-item" onClick={() => handleSelect('Music')}>Music</li> */}
												{
												categoriesData
												?
												(
													categoriesData && categoriesData.length >=1
													?
													categoriesData.map((cat , index) => {
														return <li key={index} className="dropdown-item" onClick={() => handleSelect(cat.name , cat.id)}>{cat.name}</li>
													})
													:
													<li className="dropdown-item">{t("No Categories")}</li>
												)
												:
												<li className="dropdown-item">{t("Faild Load Categories")}</li>
												}
											</ul>
											</div>
										</div>
									</div>
									<input type="text" className="form-control" aria-label="Text input with dropdown button" placeholder={t("Search Products Here")}
									onChange={(e) => {
										setSearchValue(e.target.value)
									}}
									value={searchValue}
									/>
									{
										searchValue && searchValue.length >= 1
										&&
										<div className="d-flex align-items-center btn position-relative" style={{backgroundColor : "#f5f5f5"}}
										onClick={() => {
											clearSearchQuery()
										}}
										>
											<i className="fa-solid fa-eraser" style={{marginRight : "10px" , marginLeft : "10px" ,}}></i>
										</div>
									}
									<div className="d-flex align-items-center btn position-relative btn" style={{backgroundColor : searchValue && searchValue.length >= 1 ? "#eaa451" : "#f5f5f5"}}>
										<button className="btn" type="button"><i className="flaticon-loupe"
										style={{color : searchValue && searchValue.length >= 1 ? "white" : ""}}
										// onClick={searchFunc}
										onClick={handleSearch}
										></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="sticky-header main-bar-wraper navbar-expand-lg position-sticky" style={{zIndex : 999}}>
					<div className="main-bar clearfix">
						<div className="container clearfix">
							<div className="logo-header logo-dark">
								<Link to="/"
								><img src={siteLogo} alt="logo"/></Link>
							</div>
							<button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
							onClick={() => setShowLoginBtn(!showLoginBtn)}
							>
								<span></span>
								<span></span>
								<span></span>
							</button>
							{
								!user
								&&
								<div className="extra-nav">
									<div className="extra-cell">
										<Link to="/login" className="btn btn-primary btnhover">{t("Register")} / {t("Login")}</Link>	
									</div>
								</div>
							}
							<div className="header-nav navbar-collapse collapse justify-content-start" style={{zIndex: 9999999}} id="navbarNavDropdown">
								<div className="logo-header logo-dark">
									<Link to="/"
									onClick={handleCloseNav}
									><img src={siteLogo} alt="logo"/></Link>
								</div>
								<div className="search-input">
									<div className="input-group">
										{/* <div className="dropdown">
											<a className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
												Dropdown link
											</a>

											<ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
												<li><a className="dropdown-item" href="#">Action</a></li>
												<li><a className="dropdown-item" href="#">Another action</a></li>
												<li><a className="dropdown-item" href="#">Something else here</a></li>
											</ul>
										</div> */}
										<input type="text" className="form-control" aria-label="Text input with dropdown button" placeholder="Search Products Here"
										value={searchValue}
										onChange={(e) => {
											setSearchValue(e.target.value);
											if(searchValue && searchValue.length >= 1) {
												setCategoryOption(1)
											}else {
												setCategoryOption("");
												// clearSearchQuery();
											}
										}}
										/>
										<button 
										onClick={() => {
											if(searchValue && searchValue.length >= 1){
												// searchFunc();
												handleSearch();
												handleCloseNav();
											}
										}}
										className="btn" type="button"><i className="flaticon-loupe"></i></button>
									</div>
								</div>
								<div className="nav-link user-image-small-screen d-none flex-column justify-content-center align-items-center"
								 >
									{
									getProfileLoaing
									?
									<i className="fa-solid fa-arrows-rotate loading-verify-email loading-user-img-small-screen"></i>
									:
									<img src={profileData && profileData.image_url ? profileData.image_url : profileImage} alt="/" style={{width: "70px" , borderRadius : "50%"}}/>
									}
									{/* <img src={user && user.image ? user.image : profileImage} alt="profile img"/> */}
									<Link 
									className="profile-info d-flex flex-column justify-content-center align-items-center"
									onClick={handleCloseNav}
									to="/profile"
									>
										<h6 className="title m-0">{user && user.name}</h6>
										<span>{user && user.email}</span>
									</Link>
								</div>
								<ul className="nav navbar-nav">
									<li><Link to="/"
										onClick={() => handleCloseNav()}
									><span>{t("Home")}</span></Link></li>
									<li><Link to="/products"
										onClick={() => handleCloseNav()}
									><span>{t("Products")}</span></Link></li>
									{/* <li><Link to="/oredrs"
										onClick={() => handleCloseNav()}
									><span>{t("My Orders")}</span></Link></li> */}
									{/* <li><Link to="/products"
										onClick={() => handleCloseNav()}
									><span>Shop</span></Link></li> */}
									<li className="sub-menu-down"><Link onClick={() => {
										setShowCategoriesInSmScreens(!showCategoriesInSmScreens)
									}}><span>{t("Categories")}</span></Link>
									{
										categoriesData
										&&
										<ul className="sub-menu" style={{display: showCategoriesInSmScreens ? "block" : "none"}}>
											{
												categoriesData.length >= 1 && categoriesData.map((cat , index) => (
													<li key={index}><Link 
													// onClick={() => handleCloseNav()}
													to={`/products?caegory_id=${cat.id}`}
													onClick={() => handleCloseNav()}
													>{cat.name}</Link></li>
												))
											}
										</ul>
									}
									</li>
									<li><Link to="/contact-us"
										onClick={() => handleCloseNav()}
									><span>{t("Contact Us")}</span></Link></li>
								</ul>
								<ToggelerLang
									languages = {languagesList}
								/>
								{
									!user
									?
										<div className="d-none login-btn-small-screen justify-content-center mt-2">
											<Link to="/login" className="btn btn-primary btnhover"
											onClick={handleCloseNav}
											>{t("Register / Login")}</Link>	
										</div>
									:
									<div className="d-none login-btn-small-screen justify-content-center mt-2">
										<Link className="btn btn-primary btnhover"
										onClick={handleLogout}
										>{t("LogOut")}</Link>	
									</div>

								}
								<div className="dz-social-icon">
									<ul>
										<li><Link 
										onClick={handleCloseNav}
										className="fab fa-facebook-f" target="_blank" to={facebook_link}></Link></li>
										<li><Link 
										onClick={handleCloseNav}
										className="fab fa-twitter" target="_blank" to={twitter_link}></Link></li>
										<li><Link 
										onClick={handleCloseNav}
										className="fab fa-linkedin-in" target="_blank" to={linkedin_link}></Link></li>
										<li><Link 
										onClick={handleCloseNav}
										className="fab fa-instagram" target="_blank" to={instagram_link}></Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
        </>
    )
}



// <select className="default-select"
// 									onChange={(e) => {
// 										setSearchValue(e.target.value)
// 									}}
// 									>
// 										{/* {
// 											categoriesData
// 											?
// 											(
// 												categoriesData && categoriesData.length >=1
// 												?
// 												categoriesData.map((cat , index) => {
// 													return <option  value={cat.id}>{cat.name}</option>
// 												})
// 												:
// 												<option disabled selected>No Categories</option>
// 											)
// 											:
// 											<option disabled>Faild Load Categories</option>
// 										} */}
// 										<option>Category</option>
// 										<option>Photography </option>
// 										<option>Arts</option>
// 										<option>Adventure</option>
// 										<option>Action</option>
// 										<option>Games</option>
// 										<option>Movies</option>
// 										<option>Category</option>
// 										<option>Biographies</option>
// 										<option>Children’s Books</option>
// 										<option>Historical</option>
// 										<option>Contemporary</option>
// 										<option>Classics</option>
// 										<option>Education</option>
// 									</select>

