import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../../global/global-functions/checkRequestErrors";
import { jwtDecode } from "jwt-decode";

export const registerFunc = createAsyncThunk(
    "register",
    async ({api , data}) => {
        // const token = window.localStorage.getItem("token");
        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${token}` // Include the token in the Authorization header
        //     }
        // }
        // // axios.defaults.headers.common["Content-Type"] = "application/json";
        // // axios.defaults.headers.common["Accept"] = "application/json";
        try {
            const response = await axios.post(api , data);
            if(response.data.success === true){
                const token = response.data.data.authorisation.token;
                const decodeToken = jwtDecode(response.data.data.authorisation.token);
                localStorage.setItem("expire_date", decodeToken.exp);
                localStorage.setItem("token", token);
                const user = {
                    id : response.data.data.user.id,
                    name : `${response.data.data.user.first_name} ${response.data.data.user.last_name}`,
                    email : response.data.data.user.email,
                    image : response.data.data.user.image_url,
                    verifiedStatus : response.data.data.user.is_email_verified,
                }
                localStorage.setItem("user", JSON.stringify(user));
                return response.data
            }
        }
        catch (error) {
            checkErrors(error.response)
        }
    }
);

const registerSlicer = createSlice({
    name : "register",
    initialState : {
        loading : false,
        status : null,
        error : false
    },
    extraReducers : (builder) => {
        builder.addCase(registerFunc.pending , (state) =>{
            state.loading = true
            state.status = null
            state.error = false
        })
        builder.addCase(registerFunc.rejected , (state) =>{
            state.loading = false
            state.status = null
            state.error = true
        })
        builder.addCase(registerFunc.fulfilled , (state , action) =>{
            state.loading = false
            state.status = action.payload
            state.error = false
        })
    }
})
export default registerSlicer.reducer;