import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const deleteFromCartFunc = createAsyncThunk(
    "cart/delete",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.delete(api , config);
            console.log(response.data)
            return response.data
        }
        catch(error) {
            console.log(error);
        }
    }
)
const deleteFromCartSlicer = createSlice({
    name : "cart/delete",
    initialState : {
        loading : false,
        data : null,
        error : false
    },
    extraReducers : builder => {
        builder.addCase(deleteFromCartFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = false
        })
        builder.addCase(deleteFromCartFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(deleteFromCartFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        })
    }
})
export default deleteFromCartSlicer.reducer