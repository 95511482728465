import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/global-functions/checkRequestErrors";
export const addNewAdressFunc = createAsyncThunk(
    "adresses/add",
    async ({data , api}) => {
        try {
            const token = window.localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            }
            const response = await axios.post(api , data , config);
            console.log(response)
            return response.data
        }
        catch(error) {
            checkErrors(error.response , error.message);
        }
    }
)
const addNewAdressSlicer = createSlice({
    name : "adresses/add",
    initialState : {
        loading : null,
        data : {},
        error : null
    },
    reducers : {},
    extraReducers : builder => {
        builder.addCase(addNewAdressFunc.pending , (state) => {
            state.loading = true
        })
        builder.addCase(addNewAdressFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(addNewAdressFunc.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        })
    }
})

export default addNewAdressSlicer.reducer