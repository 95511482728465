import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../../global/global-functions/checkRequestErrors";

export const refreshSessionFunc = createAsyncThunk(
    "session/refresh",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Accept"] = "application/json";
        try {
            const response = await axios.post(api , {} , config);
            return response.data
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
    }
);

const refreshSessionSlicer = createSlice({
    name : "session/refresh",
    initialState : {
        loading : false,
        data : null,
        error : false
    },
    extraReducers : (builder) => {
        builder.addCase(refreshSessionFunc.pending , (state) =>{
            state.loading = true
            state.data = null
            state.error = false
        })
        builder.addCase(refreshSessionFunc.rejected , (state) =>{
            state.loading = false
            state.data = null
            state.error = true
        })
        builder.addCase(refreshSessionFunc.fulfilled , (state , action) =>{
            state.loading = false
            state.data = action.payload
            state.error = false
        })
    }
})
export default refreshSessionSlicer.reducer;